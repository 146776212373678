import React from "react"
import "./Gigbox.scss"

const GigBox = function () {
  let text = "Lorem ipsum dolor sit amet consectetur adipiscing elit mi potenti dictum natoque proin, faucibus porttitor condimentum risus at semper nam a netus metus lectus. Fermentum eros aliquet elementum enim et cum vivamus blandit purus vestibulum, sapien penatibus natoque donec pellentesque at cursus dui nullam convallis mauris, lobortis mus augue ligula velit platea ad hac est. Placerat erat himenaeos gravida potenti habitant senectus venenatis metus, sed at nunc odio vestibulum blandit sagittis convallis, dis torquent felis nibh proin nisi cum."
  let chats = [];

  for (let i = 0; i < 7; i++) {
    const textStart = Math.ceil(Math.floor(Math.random() * (text.length - 0)) + 0);
    const nameStart = Math.ceil(Math.floor(Math.random() * (text.length - 0)) + 0);
    const randomLength = Math.ceil(Math.floor(Math.random() * (100 - 15)) + 15);

    const name = text.substring(nameStart, nameStart + 10);
    const msg = text.substring(textStart, textStart + randomLength);
    
    chats.push({
      id: i,
      name: name.charAt(0).toUpperCase() + name.substring(1, name.length),
      msg: msg.charAt(0).toUpperCase() + msg.substring(1, msg.length),
      datetime: "5.67pm"
    })
  }

  return (
    <div className="gig-box columns">
      <div className="column is-two-thirds">
        <div className="image is-16by9">
          <iframe
            className="has-ratio"
            width="640"
            height="360"
            src="https://www.youtube.com/embed/YE7VzlLtp-4?showinfo=0"
            frameborder="0"
            allowfullscreen
          ></iframe>
        </div>
      </div>
      <div className="chat-box column is-light">
        {chats.map(chat => (
          <div className="media">
            <div className="media-left">
              <p className="image is-32x32">
                <img src="https://bulma.io/images/placeholders/128x128.png" />
              </p>
            </div>
            <div className="media-content">
              <div className="content">
                <p className="is-size-7">
                  <strong>{chat.name}</strong> <small>{chat.datetime}</small>
                  <br />
                  {chat.msg}
                </p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default GigBox
