import React from "react"
import Layout from "../components/Layout/Layout"
import PricingTable from "../components/PricingTable/PricingTable"
import HowItWorks from "../components/HowItWorks/HowItWorks"
import SEO from "../components/Seo/Seo"
import GigBox from "../components/GigBox/GigBox"

const GigPage = function () {

  return (
    <Layout header={true} menu={false} footer={true}>
      <SEO title="Gig" />
        <div className="container">
          <div className="columns">
            <div className="column content">
              <GigBox></GigBox>
            </div>
          </div>
        </div>
    </Layout>
  );
}

export default GigPage;
